<template>
  <div v-if="independent && settings">
    <div class="flex justify-content-between"
         :class="{'flex-row gap-4 mt-6': getIsBrowserApp, 'flex-column mt-2': !getIsBrowserApp}"
    >
      <div class="w-full" :class="{'flex flex-column left-cards cards gap-3': getIsBrowserApp, '': !getIsBrowserApp}">
        <OuterCardTitle v-if="localPay" value="Paie"/>
        <Card v-if="localPay"
              :class="{'special-card mb-2 bg-transparent shadow-none': !getIsBrowserApp}"
        >
          <template #content>
            <div class="grid formgrid p-fluid">
              <template v-if="true">
                <div v-for="(mission) in missions" :key="mission.id" class="col-12 grid formgrid p-fluid">
                  <div class="field mb-3 col-12 xl:col-3 ">
                    <span class=" text-600 font-medium">{{ mission.clientContract.name }}</span>
                  </div>
                  <div class="field mb-3 col-12 xl:col-9 ">
                    <span class="text-lg text-900 font-medium">{{ `${getDateFormatted(mission.jsonMission.startingDate)}${getMissionEndingDate(mission, constants) ? `- ${getDateFormatted(getMissionEndingDate(mission, constants))}` : ''}` }}</span>
                  </div>
                </div>
                <div class="field mb-3 col-12 xl:col-3 ">
                  <span class=" text-600 font-medium">Contrat</span>
                </div>
                <div class="field mb-3 col-12 xl:col-9 ">
                  <span class="text-lg text-900 font-medium">{{ independent.profil.workContract.contract }}</span>
                </div>
                <div class="field mb-3 col-12 xl:col-3 ">
                  <span class=" text-600 font-medium">Temps de travail</span>
                </div>
                <div class="field mb-3 col-12 xl:col-9 ">
                <span class="text-lg text-900 font-medium">{{ independent.profil.workContract?.workTime?.type === "full" ? `Temps plein (${independent.profil.workContract.workTime.value}h)` :
                    independent.profil.workContract?.workTime?.type === "partial" ? `Temps partiel (${independent.profil.workContract.workTime.value}h)` : 'Indéfini'}}</span>
                </div>
                <div v-if="independent.sortie.date" class="field mb-3 col-12 xl:col-3 ">
                  <span class=" text-600 font-medium">Date de sortie prévue</span>
                </div>
                <div v-if="independent.sortie.date" class="field mb-3 col-12 xl:col-9 ">
                  <span class="text-lg text-900 font-bold">{{ getDateFormatted(independent.sortie.date) }}</span>
                </div>
                <div v-if="independent.sortie.date" class="field mb-3 col-12 xl:col-3 ">
                  <span class=" text-600 font-medium">Motif de sortie</span>
                </div>
                <div v-if="independent.sortie.date" class="field mb-6 col-12 xl:col-9 ">
                  <span class="text-lg text-900 font-medium">{{ independent.sortie.type }}</span>
                </div>
              </template>

              <template v-if="true">
                <label class="font-bold text-lg w-12 mb-3">Forfaits de petits déplacements</label>
                <label class="font-bold text-md w-12 mb-3">Midi ({{ settings.Paie.avantagesSociaux.forfaitRepasMidi ? (settings.Paie.avantagesSociaux.forfaitRepasMidi / 100).toFixed(2) : 0 }} €) :</label>
                <div class="field mb-3 col-12 xl:col-6">
                  <label for="smLunchQuantity">Quantité</label>
                  <InputNumber id="smLunchQuantity" v-model="jsonPaySmLunch" highlightOnFocus
                               :disabled="!isSavable" :min="0"/>
                </div>
                <div class="field mb-3 col-12 xl:col-6">
                  <label for="smLunchAmount">Montant</label>
                  <InputNumber id="smLunchAmount" :model-value="localPay.jsonPay.smLunch ? localPay.jsonPay.smLunch * (settings.Paie.avantagesSociaux.forfaitRepasMidi / 100) : 0"
                               disabled :min="0" locale="fr-FR" mode="currency" currency="EUR" highlightOnFocus/>
                </div>
              </template>

              <template v-if="settings.Paie.avantagesSociaux.forfaitGrandsDeplacements.value">
                <label class="font-bold text-lg w-12 mb-3">Forfaits de grands déplacements</label>
                <label class="font-bold text-md w-12 mb-3">Midi ({{ settings.Paie.avantagesSociaux.forfaitRepasMidi ? (settings.Paie.avantagesSociaux.forfaitRepasMidi / 100).toFixed(2) : 0 }} €) :</label>
                <div class="field mb-3 col-12 xl:col-6">
                  <label for="lgLunchQuantity">Quantité</label>
                  <InputNumber id="lgLunchQuantity" v-model="jsonPayLgLunch" highlightOnFocus
                               :disabled="!isSavable" :min="0"/>
                </div>
                <div class="field mb-3 col-12 xl:col-6">
                  <label for="lgLunchAmount">Montant</label>
                  <InputNumber id="lgLunchAmount" :model-value="localPay.jsonPay.lgLunch ? localPay.jsonPay.lgLunch * (settings.Paie.avantagesSociaux.forfaitRepasMidi / 100) : 0"
                               disabled :min="0" locale="fr-FR" mode="currency" currency="EUR" highlightOnFocus/>
                </div>
                <label class="font-bold text-md w-12 mb-3">Soir ({{ settings.Paie.avantagesSociaux.forfaitRepasSoir ? (settings.Paie.avantagesSociaux.forfaitRepasSoir / 100).toFixed(2) : 0 }} €) :</label>
                <div class="field mb-3 col-12 xl:col-6">
                  <label for="lgDinnerQuantity">Quantité</label>
                  <InputNumber id="lgDinnerQuantity" v-model="localPay.jsonPay.lgDinner" highlightOnFocus
                               :disabled="!isSavable" :min="0"/>
                </div>
                <div class="field mb-3 col-12 xl:col-6">
                  <label for="lgDinnerAmount">Montant</label>
                  <InputNumber id="lgDinnerAmount" :model-value="localPay.jsonPay.lgDinner ? localPay.jsonPay.lgDinner * (settings.Paie.avantagesSociaux.forfaitRepasSoir / 100) : 0"
                               disabled :min="0" locale="fr-FR" mode="currency" currency="EUR" highlightOnFocus/>
                </div>

                <label class="font-bold text-lg w-12 mb-3">Hôtels</label>
                <div v-for="(lg, index) in localPay.jsonPay.lgHotel" :key="index" class="col-12 grid formgrid p-fluid">
                  <div class="field mb-3 col-12 xl:col-4">
                    <label :for="'hotelBareme' + index">Barème</label>
                    <Dropdown :for="'hotelBareme' + index" v-model="localPay.jsonPay.lgHotel[index].forfait" class="h-auto"
                              option-label="label" :options="forfaitsHotelComputed" :disabled="!isSavable"
                              placeholder="Choisir un barème" show-clear
                              @change="(event) => changeHotelFixedPrice(event, index)"/>
                  </div>
                  <div class="field mb-3 col-6 xl:col-4">
                    <label :for="'hotelQuantity' + index">Quantité</label>
                    <InputNumber :id="'hotelQuantity' + index" v-model="localPay.jsonPay.lgHotel[index].quantity" highlightOnFocus
                                 :disabled="!isSavable" :min="0"/>
                  </div>
                  <div class="field mb-3 col-6 xl:col-4">
                    <label>&nbsp;</label>
                    <div class="flex flex-row align-items-center">
                      <Button v-if="isSavable && localPay.jsonPay.lgHotel.length > 1 && hasAccess($route.name, true)" icon="pi pi-minus"
                              class="p-button-text p-button-danger"
                              @click="removeForfaitHotel(index)"/>
                      <Button v-if="isSavable && hasAccess($route.name, true) && index === localPay.jsonPay?.lgHotel?.length - 1"
                              icon="pi pi-plus" class="p-button-text"
                              :style="index === localPay.jsonPay?.lgHotel?.length - 1 ? 'visibility: visible' : 'visibility: hidden'"
                              @click="addForfaitHotel"/>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </template>
        </Card>
        <Card v-if="localPay"
              :class="{'special-card mb-2 bg-transparent shadow-none': !getIsBrowserApp}">
          <template #content>
            <div class="grid formgrid p-fluid">
              <div class="field mb-3 col-12">
                <label class="font-bold" for="commercial">Commentaires du porté</label>
                <Textarea :disabled="!isSavable || me.role.name === constants.ROLES.ADMIN"
                          id="note" style="width: 100%; border-radius: 10px" :autoResize="true" rows="5" v-model="localPay.jsonPay.note"/>
              </div>
            </div>
          </template>
        </Card>
        <Card v-if="me.role.name === constants.ROLES.ADMIN && localPay"
              :class="{'special-card mb-2 bg-transparent shadow-none': !getIsBrowserApp}">
          <template #content>
            <div class="grid formgrid p-fluid">
              <div class="field mb-3 col-12">
                <label class="font-bold" for="commercial">Observations de la paie</label>
                <Textarea id="notes" v-model="localPay.notes" :disabled="!isSavable" style="width: 100%; border-radius: 10px"
                          :autoResize="true" rows="5"/>
              </div>
            </div>
          </template>
        </Card>
        <Card v-if="localPay" :class="{'special-card mb-2 bg-transparent shadow-none': !getIsBrowserApp}">
          <template #content>
            <div class="grid formgrid p-fluid">
              <div class="field mb-3 col-12">
                <label for="absences" class="font-bold">Absences</label>
                <Textarea id="absences" style="width: 100%; border-radius: 10px" :autoResize="true" rows="5" v-model="localPay.absences"
                          :disabled="!me.role.name === constants.ROLES.ADMIN || !isSavable"/>
              </div>
            </div>
          </template>
        </Card>
      </div>
      <div class="w-full flex flex-column right-cards cards gap-3">
        <OuterCardTitle v-if="localPay" value="Salaire"/>
        <Card v-if="localPay" :class="{'special-card mb-2 bg-transparent shadow-none': !getIsBrowserApp}">
          <template #content>
            <div class="grid formgrid p-fluid">
              <div class="field mb-3 col-12 xl:col-6">
                <label for="salaryPas">Salaire après impôts</label>
                <InputNumber id="salaryPas" v-model="localPay.jsonPay.salaryPas" :min="0" :disabled="!isSavable"
                             locale="fr-FR" mode="currency" currency="EUR" placeholder="ex : 1000 €" highlightOnFocus/>
              </div>
              <div class="field mb-3 col-12 xl:col-6">
                <label for="bountyPass">Prime nette après impôts</label>
                <InputNumber id="bountyPass" v-model="localPay.jsonPay.bountyPass" :min="0" :disabled="!isSavable"
                             locale="fr-FR" mode="currency" currency="EUR" placeholder="ex : 1000 €" highlightOnFocus/>
              </div>
              <div class="field col-12 md:col-12">
                <div  class="flex flex-column gap-2">
                  <label class="font-bold">Maintien de salaire</label>
                  <div class="flex flex-wrap gap-3">
                    <div class="flex align-items-center">
                      <label class="mr-2" for="maintains-yes">Oui</label>
                      <RadioButton id="maintains-yes" v-model="localPay.jsonPay.maintains" :value="true" :disabled="!isSavable"/>
                    </div>
                    <div class="flex align-items-center">
                      <label class="mr-2" for="maintains-no">Non</label>
                      <RadioButton id="maintains-no" v-model="localPay.jsonPay.maintains" :value="false" :disabled="!isSavable"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </Card>
        <OuterCardTitle v-if="localPay" value="Informations"/>
        <Card v-if="localPay" :class="{'special-card mb-2 bg-transparent shadow-none': !getIsBrowserApp}">
          <template #content>
            <div class="grid formgrid p-fluid">
              <div v-if="settings.RH.ticketsRestaurant.value && independent.restaurants_tickets" class="field mb-3 col-12 xl:col-6">
                <label for="ticket">Tickets restaurants acquis</label>
                <InputNumber id="ticket" v-model="localPay.jsonInfo.obtainedTickets" class="w-full" highlightOnFocus
                             type="text" :disabled="!me.role.name === constants.ROLES.ADMIN || !isSavable"
                             @blur="updateTotalRestaurantTickets"/>
              </div>
              <div v-if="settings.RH.ticketsRestaurant.value && independent.restaurants_tickets" class="field mb-3 col-12 xl:col-6">
                <label for="clientInvitation">Invitations clients</label>
                <InputNumber id="clientInvitation" :model-value="localPay.jsonInfo.clientInvitations" class="w-full" highlightOnFocus
                             disabled type="text"/>
              </div>
              <div v-if="settings.RH.ticketsRestaurant.value && independent.restaurants_tickets" class="field mb-3 col-12">
                <label for="clientInvitation">Nombre total de tickets restaurants</label>
                <InputNumber id="clientInvitation" :model-value="localPay.jsonInfo.ticket" class="w-full" highlightOnFocus
                             disabled type="text"/>
              </div>
              <div v-if="settings.Paie.avantagesSociaux.teletravail.value" class="field mb-3 col-12">
                <label for="remote-work">Forfait télétravail</label>
                <InputNumber id="remote-work" v-model="localPay.jsonInfo.forfait" type="text" class="w-full"
                             disabled locale="fr-FR" mode="currency" currency="EUR" highlightOnFocus/>
              </div>
              <label v-if="localPay?.jsonInfo?.subscriptionsTransport" class="font-bold text-lg w-12 mb-3">Abonnements de transport</label>
              <div v-for="(subscribeTransport, index) in localPay?.jsonInfo?.subscriptionsTransport" :key="index" class="col-12 grid formgrid p-fluid">
                <div class="field mb-3 col-12 xl:col-6">
                  <label :for="'subscribeTransportLabel' + index">Nom</label>
                  <InputText :id="'subscribeTransportLabel' + index" v-model="localPay.jsonInfo.subscriptionsTransport[index].label"
                             type="text" :disabled="!isSavable"/>
                </div>
                <div class="field mb-3 col-12 xl:col-6">
                  <label :for="'subscribeTransportLabel' + index">Date de fin de validité</label>
                  <Calendar id="missionStartingDate" v-model="localPay.jsonInfo.subscriptionsTransport[index].endingDate" :showIcon="true"
                            date-format="dd/mm/yy" :disabled="!isSavable" :manualInput="false" class="calendar-custom h-auto"
                            input-class="h-auto"/>
                </div>
                <div class="field mb-3 col-12 xl:col-6">
                  <label :for="'subscribeTransportPrice' + index">Montant</label>
                  <InputNumber :id="'subscribeTransportPrice' + index" :disabled="!isSavable"
                               v-model="localPay.jsonInfo.subscriptionsTransport[index].price" :min="0" locale="fr-FR" mode="currency" highlightOnFocus
                               currency="EUR"/>
                </div>
                <div class="field mb-3 col-12 xl:col-6">
                  <label :for="'subscribeTransportRefundRate' + index">Remboursement</label>
                  <InputNumber :id="'subscribeTransportRefundRate' + index" v-model="localPay.jsonInfo.subscriptionsTransport[index].refundRate"
                               :disabled="!isSavable" :min="0" locale="fr-FR" suffix="%" highlightOnFocus/>
                </div>
                <div class="field col-12 md:col-6">
                  <div  class="flex flex-column gap-2">
                    <label class="font-bold">Période</label>
                    <div class="flex flex-wrap gap-3">
                      <div class="flex align-items-center">
                        <label class="mr-2" :for="'subscribeTransportPeriodicityAnnual' + index">
                          {{ getOptionLabel(constants.data.subscriptionTransportPeriodicityOptions, constants.SUBSCRIPTION_TRANSPORT_PERIODICITY.YEARLY) }}
                        </label>
                        <RadioButton :id="'subscribeTransportPeriodicityAnnual' + index"
                                     v-model="localPay.jsonInfo.subscriptionsTransport[index].periodicity" :disabled="!isSavable"
                                     :value="constants.SUBSCRIPTION_TRANSPORT_PERIODICITY.YEARLY"/>
                      </div>
                      <div class="flex align-items-center">
                        <label class="mr-2" :for="'subscribeTransportPeriodicityMonthly' + index">
                          {{ getOptionLabel(constants.data.subscriptionTransportPeriodicityOptions, constants.SUBSCRIPTION_TRANSPORT_PERIODICITY.MONTHLY) }}
                        </label>
                        <RadioButton :id="'subscribeTransportPeriodicityMonthly' + index"
                                     v-model="localPay.jsonInfo.subscriptionsTransport[index].periodicity" :disabled="!isSavable"
                                     :value="constants.SUBSCRIPTION_TRANSPORT_PERIODICITY.MONTHLY"/>
                      </div>
                      <div class="flex align-items-center">
                        <label class="mr-2" :for="'subscribeTransportPeriodicityWeekly' + index">
                          {{ getOptionLabel(constants.data.subscriptionTransportPeriodicityOptions, constants.SUBSCRIPTION_TRANSPORT_PERIODICITY.WEEKLY) }}
                        </label>
                        <RadioButton :id="'subscribeTransportPeriodicityWeekly' + index"
                                     v-model="localPay.jsonInfo.subscriptionsTransport[index].periodicity" :disabled="!isSavable"
                                     :value="constants.SUBSCRIPTION_TRANSPORT_PERIODICITY.WEEKLY"/>
                      </div>
                      <div class="flex align-items-center">
                        <label class="mr-2" :for="'subscribeTransportPeriodicityNone' + index">
                          {{ getOptionLabel(constants.data.subscriptionTransportPeriodicityOptions, constants.SUBSCRIPTION_TRANSPORT_PERIODICITY.NONE) }}
                        </label>
                        <RadioButton :id="'subscribeTransportPeriodicityNone' + index"
                                     v-model="localPay.jsonInfo.subscriptionsTransport[index].periodicity" :disabled="!isSavable"
                                     :value="constants.SUBSCRIPTION_TRANSPORT_PERIODICITY.NONE"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="field mb-3 col-12 xl:col-4">
                  <label :for="'subscribeTransportFileProof' + index">Justificatif</label>
                  <div>
                    <Button v-if="hasAccess($route.name, true) && (localPay.jsonInfo.subscriptionsTransport[index].proofDocument || isSavable)"
                            :id="'subscribeTransportFileProof' + index" size="small"
                            :icon="localPay.jsonInfo.subscriptionsTransport[index].proofDocument ? 'pi pi-eye' : 'pi pi-upload'"
                            @click="openProofDocument(localPay.jsonInfo.subscriptionsTransport[index], index)"/>
                  </div>
                </div>
                <div class="field mb-3 col-12 xl:col-2">
                  <label>&nbsp;</label>
                  <div class="flex flex-row align-items-center">
                    <Button v-if="isSavable && localPay.jsonInfo?.subscriptionsTransport?.length > 1 && hasAccess($route.name, true)"
                            icon="pi pi-minus" class="p-button-text p-button-danger" @click="removeSubscriptionTransport(index)"/>
                    <Button v-if="isSavable && hasAccess($route.name, true) && index === localPay.jsonInfo?.subscriptionsTransport?.length - 1"
                            :style="index === localPay.jsonInfo?.subscriptionsTransport?.length - 1 ? 'visibility: visible' : 'visibility: hidden'"
                            icon="pi pi-plus" class="p-button-text" @click="addSubscriptionTransport"/>
                  </div>
                </div>
              </div>
              <div v-if="settings.Paie.avantagesSociaux.pee" class="field mt-3 mb-3 col-12 xl:col-6">
                <label for="pee">PEE (Abondement brut)</label>
                <InputNumber id="pee" v-model="localPay.jsonInfo.pee" :min="0" locale="fr-FR" :disabled="!isSavable"
                             mode="currency" currency="EUR" placeholder="ex : 1000 €" highlightOnFocus/>
              </div>
              <div v-if="settings.Paie.avantagesSociaux.perco" class="field mt-3 mb-3 col-12 xl:col-6">
                <label for="perco">PERCO (Abondement brut)</label>
                <InputNumber id="perco" v-model="localPay.jsonInfo.perco" :min="0" locale="fr-FR" :disabled="!isSavable"
                             mode="currency" currency="EUR" placeholder="ex : 1000 €" highlightOnFocus/>
              </div>
              <div class="field mb-3 col-12 xl:col-12">
                <label for="iban">IBAN</label>
                <InputText id="iban" type="text" v-model="independent.profil.workContract.ibanSalary" disabled/>
              </div>
              <div class="field mb-3 col-12 xl:col-12">
                <label for="address">Adresse</label>
                <InputText id="address" :model-value="independent.profil.address + ', ' + independent.profil.zipCode + ', ' + independent.profil.city"
                           disabled type="text"/>
              </div>
              <div v-if="me.role.name === constants.ROLES.ADMIN" class="field mb-3 col-12 xl:col-12">
                <label for="advancePaymentReceived">Acompte reçu</label>
                <InputNumber :disabled="!isSavable" id="advancePaymentReceived" highlightOnFocus
                             v-model="localPay.jsonInfo.advancePaymentReceived" :min="0" locale="fr-FR" mode="currency"
                             currency="EUR" placeholder="ex : 1000 €"/>
              </div>
              <div v-if="settings.Paie.avantagesSociaux.chomagePartiel" class="field mb-3 col-12 xl:col-12">
                <label for="partialUnemployment">Chômage partiel</label>
                <InputText :disabled="!isSavable" id="partialUnemployment" type="text"
                           v-model="localPay.jsonInfo.partialUnemployment"/>
              </div>
              <div class="field mb-3 col-12 xl:col-12">
                <label for="workStop">Arrêt de travail</label>
                <InputText id="workStop" v-model="localPay.jsonInfo.workStop" :disabled="!isSavable" type="text"/>
              </div>
<!--              <div class="field mb-3 col-12 xl:col-12">-->
<!--                <label for="outDate">Date de sortie</label>-->
<!--                <InputText id="outDate" :model-value="independent.sortie?.motif?.dateSortie" type="text" disabled/>-->
<!--              </div>-->
<!--              <div class="field mb-3 col-12 xl:col-12">-->
<!--                <label for="outLabel">Motif de sortie</label>-->
<!--                <InputText id="outDate" :model-value="independent.sortie?.raisonSortie?.name" type="text" disabled/>-->
<!--              </div>-->
              <div v-if="me.role.name === constants.ROLES.ADMIN" class="field mb-3 col-12 xl:col-12">
                <label for="ASTD">Avis de saisie tiers détenteurs</label>
                <InputNumber id="ASTD" v-model="localPay.jsonInfo.ASTD" :min="0" locale="fr-FR" :disabled="!isSavable"
                             mode="currency" currency="EUR" placeholder="ex : 1000 €" highlightOnFocus/>
              </div>
              <label v-if="me.role.name === constants.ROLES.ADMIN" class="font-bold text-lg w-12 mb-3">Régularisation</label>
              <div v-if="me.role.name === constants.ROLES.ADMIN" class="field mb-3 col-12 xl:col-3">
                <label for="regulSign">Signe</label>
                <Dropdown id="regulSign" v-model="localPay.jsonInfo.regul.sign" :disabled="!isSavable" input :options="signs"/>
              </div>
              <div v-if="me.role.name === constants.ROLES.ADMIN" class="field mb-3 col-12 xl:col-4">
                <label for="regulPrice">Montant</label>
                <InputNumber id="regulPrice" v-model="localPay.jsonInfo.regul.price" :disabled="!isSavable" highlightOnFocus
                             :min="0" locale="fr-FR" mode="currency" currency="EUR" placeholder="ex : 1000 €"/>
              </div>
              <div v-if="me.role.name === constants.ROLES.ADMIN" class="field mb-3 col-12 xl:col-5">
                <label for="regulLabel">Motif</label>
                <InputText id="regulLabel" v-model="localPay.jsonInfo.regul.label" :disabled="!isSavable" class="w-full"/>
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>
    <div class="spacer"></div>
    <Dialog v-model:visible="uploading" :style="{width: '500px'}" :modal="true"
            content-class="dialog-content" :show-header="false" :draggable="false">
      <div class="flex justify-content-between" v-for="key in Object.keys(filesUploading)" :key="key">
        <span>{{ key }}</span>
        <ProgressBar style="width: 50%" :value="filesUploading[key]"></ProgressBar>
      </div>
    </Dialog>
    <dialog-file-preview :is-active="filePreviewIsActive" replaceFileButton :document-id="filePreviewDocumentId"
                         :replace-file-button="isSavable && hasAccess($route.name, true)"
                         :document-type="constants.DOCUMENT_TYPE.SUBSCRIPTION_TRANSPORT_PROOF" :file-id="filePreviewFileId"
                         auto-upload-file :independent-id="independent.id" closeButton modal
                         firstUploadButtonLabel="Téléverser mon justificatif d'abonnement"
                         dialog-title="Ajouter un justificatif d'abonnement de transport"
                         @closePreview="resetDefaultFilePreview" @closeDialog="resetDefaultFilePreview"
                         @replaceFile="updateFileSubscriptionTransport" @firstFileUpload="updateFileSubscriptionTransport"/>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {getMissionEndingDate, getOptionLabel, hasAccess} from "@/utils/Misc";
import isBrowserAppMixin from "@/mixins/isBrowserAppMixin";
import {getDateFormatted} from "@/utils/Date";
import OuterCardTitle from "@/components/OuterCardTitle.vue";
import DialogFilePreview from "@/components/DialogFilePreview.vue";
export default {
  name: 'DeclarePaieForm',
  components: {DialogFilePreview, OuterCardTitle},
  mixins: [isBrowserAppMixin],
  emits: ['forceSave'],
  props: {
    isSavable: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      hasAccess: hasAccess,
      selectedForfaitHotel: [],
      signs: ['+', '-'],
      uploading: false,
      filesUploading: {},
      filesUploaded: [],

      filePreviewIsActive: false,
      filePreviewDocumentId: null,
      filePreviewFileId: null,
      subscriptionTransportIndexProofDocumentToChange: -1
    }
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      constants: state => state.constants,
      settings: state => state.misc.settings,
      missions: state => state.mission.missions,
      independent: state => state.independent.independent,
      localPay: state => state.pay.pay,
      craDate: state => state.cra.date,
      depenses: state => state.depense.depenses,
    }),
    jsonPaySmLunch: {
      get() {
        return this.$store.getters["pay/getSmLunch"];
      },
      set(newValue) {
        return this.$store.dispatch("pay/setSmLunch", newValue);
      },
    },
    jsonPayLgLunch: {
      get() {
        return this.$store.getters["pay/getLgLunch"];
      },
      set(newValue) {
        return this.$store.dispatch("pay/setLgLunch", newValue);
      }
    },
    forfaitsHotelComputed() {
      return this.settings.Paie.avantagesSociaux.forfaitHotels
    }
  },
  watch: {
    localPay () {
      if (this.localPay?.jsonPay?.lgHotel?.length === 0) {
        this.addForfaitHotel()
      }
      if (this.localPay?.jsonInfo?.subscriptionsTransport?.length === 0) {
        this.addSubscriptionTransport()
      }
    }
  },
  methods: {
    getOptionLabel,
    getMissionEndingDate,
    getDateFormatted,
    changeHotelFixedPrice(event, indexEvent) {
      const existingHotelIndex = this.localPay.jsonPay.lgHotel.findIndex((hotel, index) => hotel?.forfait?.label === event?.value?.label && index !== indexEvent)
      if (existingHotelIndex !== -1) {
        this.localPay.jsonPay.lgHotel[existingHotelIndex].forfait = null
      }
    },
    addForfaitHotel() {
      this.localPay.jsonPay.lgHotel.push({
        forfait: null,
        quantity: 0
      })
    },
    removeForfaitHotel(index) {
      if (this.localPay.jsonPay.lgHotel.length > 1) {
        this.localPay.jsonPay.lgHotel.splice(index, 1)
      }
    },
    addSubscriptionTransport() {
      this.localPay.jsonInfo?.subscriptionsTransport.push({
        label: null,
        endingDate: new Date(),
        price: null,
        refundRate: null,
        periodicity: null,
        proofDocument: null
      })
    },
    removeSubscriptionTransport(index) {
      if (this.localPay.jsonInfo?.subscriptionsTransport.length > 1) {
        this.localPay.jsonInfo?.subscriptionsTransport.splice(index, 1)
      }
    },
    async openProofDocument (subscriptionTransport, index) {
      this.subscriptionTransportIndexProofDocumentToChange = index
      if (subscriptionTransport.proofDocument !== null) {
        this.filePreviewDocumentId = subscriptionTransport.proofDocument
        const document = await this.$store.dispatch('documents/getOneDocument', { id: subscriptionTransport.proofDocument })
        this.filePreviewFileId = document.document
      }
      this.filePreviewIsActive = true
    },
    updateFileSubscriptionTransport (payload) {
      if (this.subscriptionTransportIndexProofDocumentToChange !== -1) {
        this.localPay.jsonInfo.subscriptionsTransport[this.subscriptionTransportIndexProofDocumentToChange].proofDocument = payload.documentId
        console.log(this.localPay.jsonInfo.subscriptionsTransport[this.subscriptionTransportIndexProofDocumentToChange])
      } else {
        console.log('pas d\'index')
      }
    },
    resetDefaultFilePreview() {
      this.filePreviewIsActive = false
      this.filePreviewFileId = null
      this.filePreviewDocumentId = null
      this.subscriptionTransportIndexProofDocumentToChange = -1
    },
    updateTotalRestaurantTickets (event) {
      // can be reset via clicking on a day to compute the total restaurant tickets automatically
      this.$store.dispatch('pay/updateTotalRestaurantTickets', event.value)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep(.p-fileupload) {
  width: 100%;
}

::v-deep(.p-fileupload-row > :first-child) {
  display: none;
}

::v-deep(.p-fileupload-row > :nth-child(2)) {
  width: 50%;
}
</style>

<style>
.special-card .p-card-body {
  padding: 0;
}
</style>

<style scoped>
.fileUploaded i:hover {
  color: red !important;
}
</style>

