<template>
  <div v-if="independent && independent.status !== null && independent.status !== constants.INDEPENDENT_STATUS.DRAFT" class="flex-container non-scrollable">
    <div v-if="getIsBrowserApp && hasAccess($route.name, true)" class="flex justify-content-end mb-3">
      <Button v-if="isSavable" type="button" class="mr-3 p-button-primary p-button-rounded" size="small" @click="save">
        {{ $t('save') }}
      </Button>
      <Button v-if="isSavable" type="button" class="p-button-primary p-button-rounded" size="small" @click="validate">
        {{ $t('validateDeclarePaie') }}
      </Button>
    </div>
    <div class="scrollable">
      <cra ref="craInde" :cra-type="constants.CRA_TYPES.PAY" class="pr-2" :cra-save-flag="LOCAL_SAVE_FLAG" @changeCurrentDate="init"/>
      <declare-paie-form :is-savable="isSavable" class="pr-2" @force-save="forceSave"/>
      <div class="spacer pr-2"/>
    </div>
  </div>
  <div v-else>
    Votre contrat de travail doit être signé pour déclarer vos premières paies
  </div>
  <div v-if="!getIsBrowserApp" class="absolute bottom-0 left-0 w-full z-2">
    <div class="relative h-auto">
      <Calendar
          v-model="currentMonth"
          class="special-mobile-calendar p-button-sm w-full justify-content-center"
          input-class="p-button"
          input-style="border-radius: 0 !important;"
          view="month"
          date-format="MM yy"
          :manual-input="false"
          touch-u-i
          @update:modelValue="changeMonth"
      />
      <div v-if="hasAccess($route.name, true)" class="h-3rem">
        <Button
            class="p-button-sm w-6 justify-content-center h-auto"
            style="border-radius: 0 0 0 10px; box-shadow: 0 0 2px rgb(0 0 0 / 25%)"
            :disabled="!isSavable"
            @click="save"
        >
          Enregistrer
        </Button>
        <Button
            class="p-button-sm w-full justify-content-center h-3rem"
            style="border-radius: 0 0 10px 0; box-shadow: 0 0 2px rgb(0 0 0 / 25%)"
            :disabled="!isSavable"
            @click="validate"
        >
          Valider la déclaration de paie
        </Button>
      </div>
    </div>
  </div>
  <DialogConfirm
      :title="'ÊTES-VOUS SÛR ?'"
      :text-question="'Êtes-vous sûr de vouloir valider les informations rentrées précédemment ?'"
      :text-info="'(Une fois validées les informations saisies ne pourront plus être modifiées)'"
      :refuse-action="refuseDialogValidation"
      :validate-action="confirmDialogValidation"
      :is-active="confirmValidationDialog"
  />
</template>

<script>

import Cra from '@/components/Cra'
import DeclarePaieForm from '@/components/Porte/DeclarePaieForm'
import {disablePComponentsOfCurrentPage, hasAccess} from "@/utils/Misc";
import {mapState} from "vuex";
import DialogConfirm from "@/components/DialogConfirm.vue";
import Alert from "@/utils/Alert";
import isBrowserAppMixin from "@/mixins/isBrowserAppMixin";

export default {
  name: 'PaiePorte',
  mixins: [isBrowserAppMixin],
  components: {DialogConfirm, Cra, DeclarePaieForm },
  props: {},
  data () {
    return {
      hasAccess: hasAccess,
      confirmValidationDialog: false,
      currentMonth: null,
      LOCAL_SAVE_FLAG: false
    }
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      constants: state => state.constants,
      craDate: state => state.cra.date,
      independent: state => state.independent.independent,
      missions: state => state.mission.missions,
      localPay: state => state.pay.pay,
      depenses: state => state.depense.depenses,
    }),
    isSavable() {
      return (
          this.localPay
          && (this.localPay.status === this.constants.PAY_STATUS.DRAFT
              || (this.me.role.name === this.constants.ROLES.ADMIN
                  && this.localPay.status === this.constants.PAY_STATUS.TO_PROCESS)
          )
      )
    }
  },
  watch: {
    '$route.query.idIndependent': {
      async handler () {
        if (this.$route.query.idIndependent) {
          await this.$refs.craInde.resetComponent() // prevent from sending wrong data to the CRA details
          await this.init({})
        }
      },
      deep: true
    }
  },
  async mounted () {
    await this.init({})
  },
  methods: {
    async init({month = null, year = null}) {
      this.$store.state.misc.loading = true
      let currentMonth = month
      let currentYear = year
      let date
      if (this.me.role.name === this.constants.ROLES.ADMIN) {
        await this.$store.dispatch('independent/getOneIndependent', this.$route.query.idIndependent)
      } else {
        await this.$store.dispatch('independent/getOneIndependentByUser', this.me.id)
      }

      if (this.independent.status === null || this.independent.status === this.constants.INDEPENDENT_STATUS.DRAFT) {
        this.$store.state.misc.loading = false
        return
      }

      if (month === null || year === null) {
        date = new Date()
        currentMonth = this.$route.query.month ? parseInt(this.$route.query.month) : date.getMonth()
        currentYear = this.$route.query.year ? parseInt(this.$route.query.year) : date.getFullYear()

        const lastPaysNotValidate = await this.$store.dispatch('pay/getLastInvalidatePays', { independentId: this.independent.id, month: currentMonth, year: currentYear})
        if (lastPaysNotValidate?.length > 0) {
          currentMonth = lastPaysNotValidate[0]?.month
          currentYear = lastPaysNotValidate[0]?.year
        }
      }

      let data = {
        month: currentMonth,
        year: currentYear
      }
      await this.$store.dispatch('cra/updateDate', data)
      this.currentMonth = new Date(Date.UTC(currentYear, currentMonth, 1))
      await this.$store.dispatch('mission/getMissionsOfCurrentMonthByIndependent', { independentId: this.independent.id, month: data.month, year: data.year })
      await this.$refs.craInde.changeCra()
      if (this.localPay?.id) {
        await this.$store.dispatch('depense/getRefundedClientInvitation', { independentId: this.independent.id, payId: this.localPay?.id })
        this.localPay.jsonInfo.clientInvitations = this.depenses?.length ?? 0
      }
      await this.$nextTick(() => {
        if (!hasAccess(this.$route.name, true)) disablePComponentsOfCurrentPage(document)
      })
      this.$store.state.misc.loading = false
    },
    changeMonth (value) {
      this.init({ month: value.getMonth(), year: value.getFullYear()})
    },
    // eslint-disable-next-line no-unused-vars
    async forceSave (payload) {
      await this.save()
      await this.$refs.craInde.changeCra()
    },
    async save() {
      this.$store.state.misc.loading = true
      // this.localPay.jsonPay.lgHotel.forEach((lg, index) => {
      //   lg.forfait = this.selectedForfaitsHotel[index]
      // })
      this.missions.forEach((m) => {
        let index = this.localPay.cras.findIndex((c) => {
          return c.id === m.currentCra.id && c.mission === m.id
        })
        if (index !== -1) {
          this.localPay.cras[index] = JSON.parse(JSON.stringify(m.currentCra))
        }
      })
      await this.$store.dispatch('pay/updatePay', this.localPay)
      this.LOCAL_SAVE_FLAG = true
      process.nextTick(() => {
        this.LOCAL_SAVE_FLAG = false
      })
      this.$store.state.misc.loading = false
      Alert.successMessage(this, 'saveDeclarePay')
    },
    validate() {
      this.confirmValidationDialog = true
    },
    refuseDialogValidation() {
      this.confirmValidationDialog = false
    },
    async confirmDialogValidation() {
      this.confirmValidationDialog = false
      this.$store.state.misc.loading = true

      this.missions.forEach((m) => {
        let index = this.localPay.cras.findIndex((c) => {
          return c.id === m.currentCra.id && c.mission === m.id
        })
        if (index !== -1) {
          this.localPay.cras[index] = JSON.parse(JSON.stringify(m.currentCra))
        }
      })
      this.LOCAL_SAVE_FLAG = true
      process.nextTick(() => {
        this.LOCAL_SAVE_FLAG = false
      })

      const mustCreateDeclarationFacturation = this.localPay.status === this.constants.PAY_STATUS.DRAFT

      this.localPay.status = this.me.role.name === this.constants.ROLES.ADMIN ? this.constants.PAY_STATUS.TO_EXPORT
              : (this.localPay.status === this.constants.PAY_STATUS.DRAFT ? this.constants.PAY_STATUS.TO_PROCESS : this.constants.PAY_STATUS.TO_EXPORT)

      await this.$store.dispatch('pay/updatePay', this.localPay)

      if (this.missions?.length > 0 && mustCreateDeclarationFacturation) {
        this.missions.forEach((m) => {
          let timesheet = []
          this.localPay.cras.forEach((c) => {
            if (c.mission === m.id) {
              c.timesheet.forEach((ts) => {
                timesheet.push(JSON.parse(JSON.stringify(ts)))
              })
            }
          })
          const newDeclaration = {
            independent: this.localPay.independent,
            month: this.localPay.month,
            year: this.localPay.year,
            timesheet: timesheet,
            status: 'noValidate',
            mission: m.id,
          }
          // todo: no waiting for the invoices to be done ?
          this.$store.dispatch('declarationFacturation/createDeclarationFacturation', newDeclaration)
        })
      }
      this.$store.state.misc.loading = false
      Alert.successMessage(this, 'declarePaieValidate')
    }
  }
}
</script>

<style scoped>
.special-mobile-calendar .p-inputtext:enabled:focus {
  box-shadow: none;
}
.special-mobile-calendar .p-button:focus {
  box-shadow: none;
}
</style>
